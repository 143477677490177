var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll-bar",
          rawName: "v-scroll-bar",
          value: { right: _vm.scrollBarRight },
          expression: "{right: scrollBarRight}",
        },
      ],
      staticClass: "scroll-list",
      style: { height: _vm.height },
    },
    [
      _c(
        "van-list",
        {
          staticClass: "content",
          attrs: { finished: _vm.loaded, offset: "20" },
          on: { load: _vm.handleLoad },
          scopedSlots: _vm._u([
            {
              key: "loading",
              fn: function () {
                return [_c("ec-webview-loading-spinner")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.loading,
            callback: function ($$v) {
              _vm.loading = $$v
            },
            expression: "loading",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }