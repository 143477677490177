<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-07-24 14:26:35.652 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="ec-webview-loading-spinner">
        <div class="ec-webview-loading-spinner spinner">
            <div class="spinner-container container1">
                <div class="circle1" />
                <div class="circle2" />
                <div class="circle3" />
                <div class="circle4" />
            </div>
            <div class="spinner-container container2">
                <div class="circle1" />
                <div class="circle2" />
                <div class="circle3" />
                <div class="circle4" />
            </div>
            <div class="spinner-container container3">
                <div class="circle1" />
                <div class="circle2" />
                <div class="circle3" />
                <div class="circle4" />
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'EcWebviewLoadingSpinner'
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.ec-webview-loading-spinner {
    .spinner {
        margin: 10px auto;
        width: 20px;
        height: 20px;
        position: relative;
    }

    .container1 > div,
    .container2 > div,
    .container3 > div {
        width: 6px;
        height: 6px;
        background-color: #03da8b;
        border-radius: 100%;
        position: absolute;
        animation: bouncedelay 1.2s infinite ease-in-out;
        animation: bouncedelay 1.2s infinite ease-in-out;
        animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .spinner .spinner-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .container2 {
        transform: rotateZ(45deg);
        transform: rotateZ(45deg);
    }

    .container3 {
        transform: rotateZ(90deg);
        transform: rotateZ(90deg);
    }

    .circle1 {
        top: 0;
        left: 0;
    }

    .circle2 {
        top: 0;
        right: 0;
    }

    .circle3 {
        right: 0;
        bottom: 0;
    }

    .circle4 {
        left: 0;
        bottom: 0;
    }

    .container2 .circle1 {
        animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .container3 .circle1 {
        animation-delay: -1s;
        animation-delay: -1s;
    }

    .container1 .circle2 {
        animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .container2 .circle2 {
        animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    .container3 .circle2 {
        animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .container1 .circle3 {
        animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

    .container2 .circle3 {
        animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .container3 .circle3 {
        animation-delay: -0.4s;
        animation-delay: -0.4s;
    }

    .container1 .circle4 {
        animation-delay: -0.3s;
        animation-delay: -0.3s;
    }

    .container2 .circle4 {
        animation-delay: -0.2s;
        animation-delay: -0.2s;
    }

    .container3 .circle4 {
        animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

    @keyframes bouncedelay {
        0%,
        80%,
        100% {
            transform: scale(0);
        }

        40% {
            transform: scale(1);
        }
    }

    @keyframes bouncedelay {
        0%,
        80%,
        100% {
            transform: scale(0);
            transform: scale(0);
        }

        40% {
            transform: scale(1);
            transform: scale(1);
        }
    }
}
</style>
