<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-6-24 14:04:33 -->
<!-- @desc scroll-list.vue -->

<template>
    <div
        v-scroll-bar="{right: scrollBarRight}"
        :style="{height}"
        class="scroll-list"
    >
        <van-list
            v-model="loading"
            :finished="loaded"
            offset="20"
            class="content"
            @load="handleLoad"
        >
            <slot />

            <template #loading>
                <ec-webview-loading-spinner />
            </template>
        </van-list>
    </div>
</template>

<script>
import {list} from 'vant';

import EcWebviewLoadingSpinner from 'easycash/common/component/ec-webview-loading-spinner';

export default {
    name: 'ScrollList',

    components: {
        EcWebviewLoadingSpinner,
        [list.name]: list
    },

    props: {
        height: {
            type: String,
            required: true
        },

        scrollBarRight: {
            type: String,
            default: '0.1rem'
        }
    },

    data() {
        return {
            loading: false,
            loaded: false
        };
    },

    methods: {
        async handleLoad() {
            this.loading = true;
            this.$emit('load', this.handleLoading, this.handleLoaded);
        },

        handleLoading() {
            this.loading = false;
        },

        handleLoaded() {
            this.loaded = true;
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.scroll-list {
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
}
</style>
