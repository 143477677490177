/**
 * @Author: quxiaoya
 * @Date: 2019-05-10 13:38:44
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:32
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    getLotteryConfig: {
        url: '/api/loan/lottery/config/:activityId',
        method: 'get'
    },

    sudokuDrawLottery: {
        url: '/api/loan/lottery/drawLottery/:activityId',
        method: 'get'
    },

    chance: {
        url: '/api/loan/lotteryActivity/chance/:activityId',
        method: 'get'
    },

    chances: {
        url: '/api/loan/lottery/chances/:activityId',
        method: 'get'
    },

    results: {
        url: '/api/loan/lottery/results/:activityId',
        method: 'get'
    },

    resultDetails: {
        url: '/api/loan/lottery/resultDetails/:lotteryLogId',
        method: 'get'
    },

    carouselRecentlyReults: {
        url: '/api/loan/lottery/carouselRecentlyResults/:activityId',
        method: 'get'
    }
};

export default Resource.create(api);
