<!-- @author zhaoyang -->
<!-- @email yangzhao03@yangqianguan.com -->
<!-- @date 2021-12-08 18:42:54 -->
<!-- @desc index.vue -->

<template>
    <div class="record-wrapper">
        <div class="record-table">
            <div class="record-table-title">
                <span class="text">{{ getI18n('recordName') }}</span>
                <span class="text">{{ getI18n('recordTime') }}</span>
            </div>
            <div>
                <scroll-list
                    height="calc(100vh - 1.5rem)"
                    scroll-bar-right="0rem"
                    @load="fetchResults"
                >
                    <template v-if="recordList && recordList.length">
                        <div
                            v-for="(item,index) in recordList"
                            :key="index"
                            class="record-table-body"
                        >
                            <span class="body-text">
                                <span class="body-text-name">
                                    {{ item.rewardName }}
                                </span>
                            </span>
                            <span class="body-text">
                                <span class="body-text-time">
                                    {{ item.timeCreated | timeTransform }}
                                </span>
                            </span>
                        </div>
                    </template>
                    <div
                        v-if="recordList !== null && recordList.length === 0"
                        class="empty"
                    >
                        <img
                            src="../lottery/img/no-item.png"
                            alt=""
                        >
                        <div class="text">
                            {{ getI18n('noRecords') }}
                        </div>
                    </div>
                </scroll-list>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollList from 'easycash/common/component/scroll-list';
import LotteryApi from 'easycash/common/resource/lottery';

export default {
    name: 'Record',

    components: {
        ScrollList
    },

    filters: {
        timeTransform(time) {
            return time.replace(/-/g, '/');
        }
    },

    data() {
        return {
            offset: 0,
            limit: 20,
            recordList: null
        };
    },

    title() {
        return this.getI18n('detailTitle');
    },

    computed: {
        activityId() {
            return this.$route.params.activityId;
        }
    },

    methods: {
        getI18n(key, params) {
            return this.$t(`webview.activity.sudokuLottery.${key}`, params);
        },

        async fetchResults(handleLoading, handleLoaded) {
            const {activityId, offset, limit, recordList} = this;
            const {data: {body: {rewardList, total}}} = await LotteryApi.results({
                params: {activityId, offset, limit}
            })
            .catch(({data: {status: {code, detail}}}) => ({
                data: {body: {rewardList: [], total: 0}},
                errCode: code,
                errMsg: detail
            }));
            this.offset += rewardList?.length ?? 0;
            this.recordList = [...(recordList ?? []), ...rewardList];
            if (this.recordList.length >= total) handleLoaded();
            handleLoading();
        },

        async fetchReward(headers) {
            const {activityId, offset} = this;
            const {data: {body}, errCode} = await LotteryApi.results({
                headers,
                params: {
                    activityId,
                    offset,
                    limit: 10
                }
            })
            .catch(({data: {status: {code, detail}}}) => ({
                data: {body},
                errCode: code,
                errMsg: detail
            }));

            return {...body, errCode};
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.record-wrapper {
    background-color: #013728;

    .record-table {
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        background-color: #fff;
        max-height: calc(100vh - 1.5rem + 49px);

        &-title {
            height: 49px;
            display: flex;
            box-sizing: border-box;
            padding: 0 0.2rem;
            background: #20b070;

            .text {
                width: 50%;
                font-size: 16px;
                font-family: "Helvetica-Bold", Helvetica;
                font-weight: bold;
                color: #fff;
                line-height: 49px;
                text-align: center;
            }
        }

        &-body {
            display: flex;
            box-sizing: border-box;
            padding: 0.2rem;
            background-color: #fff;

            &:nth-child(even) {
                background-color: #e5fff0;
            }

            .body-text {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                font-size: 14px;
                font-family: Helvetica;
                color: #185e55;
                line-height: 17px;
                text-align: center;
            }

            .body-text-name {
                display: inline-block;
                max-width: 1.35rem;
            }

            .body-text-time {
                display: inline-block;
                max-width: 1rem;
            }
        }
    }

    .empty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 0 0.2rem;
        width: 100%;
        background-color: #fff;
        font-size: 14px;
        font-weight: bold;

        img {
            width: 50%;
        }
    }
}
</style>

<style lang="scss">
    .scroll-bar {
        height: 100px;
        width: 3px;
        top: 0;
        border-radius: 3px;
        position: absolute;
        background: #a3acbc;
        transition: opacity 0.3s ease-in-out;
    }
</style>
