var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "record-wrapper" }, [
    _c("div", { staticClass: "record-table" }, [
      _c("div", { staticClass: "record-table-title" }, [
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.getI18n("recordName"))),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.getI18n("recordTime"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "scroll-list",
            {
              attrs: {
                height: "calc(100vh - 1.5rem)",
                "scroll-bar-right": "0rem",
              },
              on: { load: _vm.fetchResults },
            },
            [
              _vm.recordList && _vm.recordList.length
                ? _vm._l(_vm.recordList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "record-table-body" },
                      [
                        _c("span", { staticClass: "body-text" }, [
                          _c("span", { staticClass: "body-text-name" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.rewardName) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "body-text" }, [
                          _c("span", { staticClass: "body-text-time" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm._f("timeTransform")(item.timeCreated)
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.recordList !== null && _vm.recordList.length === 0
                ? _c("div", { staticClass: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("../lottery/img/no-item.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.getI18n("noRecords")) +
                          "\n                    "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }